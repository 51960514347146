import NgwConnector from '@nextgis/ngw-connector';

import config from '../config';

import type { NgwConnectorOptions } from '@nextgis/ngw-connector';

const ngwConnectorOptions: NgwConnectorOptions = {
  // Use webpack dev server proxy on development
  // baseUrl: __DEV__ ? '' : config.baseUrl,
  baseUrl: config.baseUrl,
  auth: config.auth,
};

export const connector = NgwConnector.create(ngwConnectorOptions);
