import { mdiAccount, mdiEyeOffOutline, mdiEyeOutline, mdiLock } from '@mdi/js';
import { config } from '@veles/common';
import { Component, Vue } from 'vue-property-decorator';

import VersionChip from '../../components/VersionChip/VersionChip.vue';
import { appModule } from '../../store/app/app';

import type { Credentials, UserInfo } from '@nextgis/ngw-connector';

@Component<Login>({ components: { VersionChip } })
export default class Login extends Vue {
  svg = {
    mdiAccount,
    mdiLock,
    showPassword: mdiEyeOutline,
    hidePassword: mdiEyeOffOutline,
  };
  isLoading = false;
  isError = false;
  isPasswordShow = false;
  auth: Credentials = { login: '', password: '' };

  get appName(): string | undefined {
    return appModule.appName;
  }

  get ngw(): string | undefined {
    return config.baseUrl;
  }

  get isValid(): boolean {
    return !!(this.auth.login && this.auth.password);
  }

  get user(): UserInfo | null {
    return appModule.user;
  }

  get welcomeText() {
    return config.welcomeText;
  }

  get demoMode() {
    return config.demoMode;
  }

  async login(): Promise<void> {
    this.isError = false;
    this.isLoading = true;
    try {
      await appModule.login(this.auth);
    } catch (er) {
      // console.log(er);
    } finally {
      this.isLoading = false;
    }
    this.isError = !this.user;
  }

  showPassword(): void {
    this.isPasswordShow = !this.isPasswordShow;
  }
}
