import { Component, Mixins } from 'vue-property-decorator';

import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

import type { ConfirmDialogOptions } from '../ConfirmDialog/ConfirmDialog';
// import ConfirmDialog from '../ConfirmDialog/ConfirmDialog.vue';

export interface SelectFileDialogOptions extends ConfirmDialogOptions {
  /**
   * @example .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document
   */
  accept?: string;
  multiple?: boolean;
}

type ToReturn = File | File[] | null;

@Component({ name: 'select-file-dialog-component' })
export default class SelectFileDialog extends Mixins<
  ConfirmDialog<SelectFileDialogOptions, ToReturn>
>(ConfirmDialog) {
  files: ToReturn = null;
  message = 'Вы действительно хотите выполнить это действие';
  title = 'Выбор файла';

  options: SelectFileDialogOptions = {
    color: 'primary',
    width: 500,
    zIndex: 200,
    acceptText: 'Выбрать',
    rejectText: 'Отменить',
    rejectBtn: true,
  };

  onResolve(): ToReturn {
    return this.files;
  }
}
