import { config, connector } from '@veles/common';
import { LoadingComponent } from '@veles/common/src/components/LoadingComponent';
import { SelectFileDialogComponent } from '@veles/common/src/components/SelectFileDialog';
import { Component, Vue, Watch } from 'vue-property-decorator';

import pkg from '../../../package.json';
import { getCatalog } from '../../catalog';
import { resetRouter } from '../../routers';
import { appModule } from '../../store/app/app';

import type { UserInfo } from '@nextgis/ngw-connector';
import type { Route, RouteConfig } from 'vue-router';

@Component({
  components: {
    LoadingComponent,
    SelectFileDialogComponent,
  },
})
export default class App extends Vue {
  next!: string;

  get appName(): string | undefined {
    return appModule.appName;
  }

  get version(): string {
    return pkg.version;
  }

  get globalLoading(): boolean {
    return appModule.globalLoading;
  }
  get globalErrorMessage(): string {
    return appModule.globalErrorMessage;
  }

  get loadingText(): string {
    return this.appName + ' загрузка...';
  }

  get user(): UserInfo | null {
    return appModule.user;
  }

  @Watch('$route')
  changeTitle(to: Route): void {
    document.title = (to.meta && to.meta.title) || 'NextGIS LES';
  }

  @Watch('user')
  async goNext(): Promise<void> {
    const path = this.next || '/';
    appModule.setGlobalLoading(true);
    try {
      await config.applyRemoteConfig(connector);
      const catalog = getCatalog();
      await appModule.setCatalog(catalog);
      this.updateRoute();
    } catch (er) {
      console.log('cant load app config', er);
      appModule.setGlobalErrorMessage(
        'Не удалось загрузить настройки приложения',
      );
    } finally {
      appModule.setGlobalLoading(false);
    }
    this.$router.push({ path, query: {} });
  }

  beforeMount(): void {
    const next = this.$route.query.next as string;
    if (next) {
      this.next = next;
    }
    if (this.user) {
      this.goNext();
    }
  }

  async logOut(): Promise<void> {
    await appModule.logout();
    this.$router.push('/login');
  }

  mounted(): void {
    this.$root.$file = this.$refs.file as any;
  }

  updateRoute(): void {
    const catalog: RouteConfig[] = [];
    for (const x of appModule.catalog) {
      for (const y of x.actions) {
        let addToRoute = true; // y.name !== 'open'
        if (y.name === 'open' && y.link) {
          addToRoute = false;
        }
        if (addToRoute) {
          const _config: RouteConfig = {
            path: '' + x.id + '/' + y.name,
            name: x.id + '_' + y.name,
            meta: { title: ` ${y.title} | ${x.title} | ${config.title}` },
            component: y.componentFactory,
          };
          if (y.router) {
            if (y.router.path) {
              _config.path += y.router.path;
            }
            if (y.router.props) {
              _config.props = y.router.props;
            }
          }
          catalog.push(_config);
        }
      }
    }
    const { routes } = this.$router.options;
    if (routes) {
      // used `path` instead of` name` to avoid the vue-router error
      const routeData = routes.find((r) => r.path === '/');
      if (routeData) {
        routeData.children = [];
        catalog.forEach((x) => {
          routeData.children?.push(x);
        });
        resetRouter([routeData]);
      }
    }
  }
}
