import { config, connector } from '@veles/common';
import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
  getModule,
} from 'vuex-module-decorators';

import store from '..';

import type {
  ActiveCatalogItem,
  CatalogItem,
  CatalogItemAction,
  CatalogItemType,
} from './interfaces';
import type { Credentials, UserInfo } from '@nextgis/ngw-connector';

const _storageKey = 'auth';
const _auth = localStorage.getItem(_storageKey);

@Module({ dynamic: true, store, name: 'user' })
export class App extends VuexModule {
  appName = config.title;

  user: UserInfo | null = null;

  catalog: CatalogItem[] = [];

  globalLoading = false;
  globalErrorMessage = '';

  activeCatalog: ActiveCatalogItem | false = false;

  private _promises: Record<string, Promise<any>> = {};

  get catalogFilter() {
    return (type: CatalogItemType): CatalogItem[] =>
      this.catalog.filter(
        (x) => (x.enabled !== undefined ? x.enabled : true) && x.type === type,
      );
  }

  get inventory(): CatalogItem[] {
    return this.catalogFilter('inventory');
  }
  get reports(): CatalogItem[] {
    return this.catalogFilter('report');
  }
  get tools(): CatalogItem[] {
    return this.catalogFilter('tools');
  }

  @Action({ commit: 'UPDATE_USER' })
  async login(auth: Credentials): Promise<UserInfo | null | undefined> {
    if (!this._promises.login) {
      this._promises.login = connector.login(auth).finally(() => {
        delete this._promises.login;
      });
    }
    const user = await this._promises.login;
    delete this._promises.login;
    if (user.keyname !== 'guest') {
      localStorage.setItem(_storageKey, JSON.stringify(auth));
      return connector.user;
    }
    return null;
  }

  @MutationAction({ mutate: ['catalog'] })
  async setCatalog(catalog: CatalogItem[]): Promise<{
    catalog: CatalogItem[];
  }> {
    return { catalog };
  }

  @MutationAction({ mutate: ['appName'] })
  async setAppName(appName: string): Promise<{
    appName: string;
  }> {
    return { appName };
  }

  @MutationAction({ mutate: ['user', 'globalErrorMessage'] })
  async logout(): Promise<{
    user: null;
    globalErrorMessage: string;
  }> {
    await connector.logout();
    localStorage.setItem(_storageKey, '');
    return { user: null, globalErrorMessage: '' };
  }

  @Action({ commit: 'UPDATE_USER' })
  async updateUser(user: UserInfo): Promise<false | UserInfo> {
    return user && user.keyname !== 'guest' && user;
  }

  @MutationAction({ mutate: ['globalLoading'] })
  async setGlobalLoading(globalLoading: boolean): Promise<{
    globalLoading: boolean;
  }> {
    return {
      globalLoading,
    };
  }

  @MutationAction({ mutate: ['globalErrorMessage'] })
  async setGlobalErrorMessage(globalErrorMessage: string): Promise<{
    globalErrorMessage: string;
  }> {
    return {
      globalErrorMessage,
    };
  }

  @Action({ commit: 'UPDATE_ACTIVE_CATALOG' })
  async setActiveCatalogAction(opt?: {
    catalogItemId: string;
    actionName: string;
  }): Promise<
    | false
    | {
        item: CatalogItem | undefined;
        action: CatalogItemAction | undefined;
      }
  > {
    if (opt) {
      const item = this.catalog.find((x) => x.id === opt.catalogItemId);
      const action =
        item && item.actions.find((x) => x.name === opt.actionName);

      return {
        item,
        action,
      };
    } else {
      return false;
    }
  }

  @Mutation
  private UPDATE_ACTIVE_CATALOG(activeCatalog: ActiveCatalogItem) {
    this.activeCatalog = activeCatalog;
  }

  @Mutation
  private UPDATE_USER(user: UserInfo) {
    this.user = user;
  }
}

export const appModule = getModule(App);

appModule.setAppName(config.title);
if (_auth) {
  connector.emitter.once('login', (data) => {
    if (connector.user) {
      appModule.updateUser(connector.user);
      appModule.setGlobalLoading(false);
    }
  });
  connector.emitter.once('login:error', (data) => {
    console.log(data);
    appModule.setGlobalLoading(false);
    appModule.setGlobalErrorMessage(
      'Невозможно выполнить вход в систему. Ошибка сервера.',
    );
  });
  appModule.setGlobalLoading(true);
  connector.login(JSON.parse(_auth)).finally(() => {
    appModule.setGlobalLoading(false);
  });
}
