/**
 * A promise you can stop
 *
 * @packageDocumentation
 * @module cancelable-promise
 */
import { CancelError } from './CancelError';
import { CancelablePromise } from './CancelablePromise';

export { CancelError };

export default CancelablePromise;
