var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.user
    ? _c(
        "v-main",
        { staticClass: "veles-background-full" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "fill-height": "" } },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "", "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs9: "", sm6: "", md3: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { align: "center", justify: "center" } },
                            [
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("@veles/app/images/logo_long_product.svg"),
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", [_c("VersionChip")], 1),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.welcomeText
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c("v-flex", {
                                    staticClass: "pt-3",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.welcomeText),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-form",
                            {
                              staticClass: "pt-4",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Имя пользователя",
                                  name: "login",
                                  "prepend-icon": _vm.svg.mdiAccount,
                                  type: "text",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.login.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.auth.login,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.auth, "login", $$v)
                                  },
                                  expression: "auth.login",
                                },
                              }),
                              _vm._v(" "),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Пароль",
                                  name: "password",
                                  "prepend-icon": _vm.svg.mdiLock,
                                  "append-icon": _vm.isPasswordShow
                                    ? _vm.svg.hidePassword
                                    : _vm.svg.showPassword,
                                  type: _vm.isPasswordShow
                                    ? "string"
                                    : "password",
                                },
                                on: {
                                  "click:append": _vm.showPassword,
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.login.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.auth.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.auth, "password", $$v)
                                  },
                                  expression: "auth.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.isError
                        ? _c(
                            "v-alert",
                            { attrs: { type: "error", dense: "" } },
                            [_vm._v("Не удалось войти")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pt-4" },
                        [
                          _c(
                            "v-col",
                            { attrs: { align: "center", justify: "center" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.isLoading,
                                    disabled: !_vm.isValid,
                                  },
                                  on: {
                                    click: _vm.login,
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.login.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v("Войти")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.demoMode
                        ? _c(
                            "v-row",
                            { staticClass: "pt-4" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    type: "warning",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Данная версия приложения предназначена для демонстрации и имеет\n            ограниченный функционал. Вы не сможете поменять топонимы, породы\n            деревьев и другие составляющие справочников. Охват всех карт\n            настроен на небольшой участок в Приморском крае. Если Вам\n            необходимо полноценное тестирование, пожалуйста, напишите нам ("
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "mailto: les@nextgis.com",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("les@nextgis.com")]
                                  ),
                                  _vm._v(").\n          "),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }