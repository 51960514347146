import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/src/locale/ru';

import type { SelectFileDialog } from '@veles/common/src/components/SelectFileDialog';
import type { Framework } from 'vuetify';

Vue.use(Vuetify);

// const storage = localStorage.getItem('is-app-light');
// const light = storage && storage === '1';

export default new Vuetify({
  theme: {
    dark: true, // !light,
    themes: {
      dark: {
        primary: '#00B9A8',
        secondary: '#00B9A8',
        accent: '#00B9A8',
        error: '#D31C32',
        info: '#25D3EB',
        success: '#1FDE99',
        warning: '#F9B023',
      },
      light: {
        // primary: '#009688',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
});

declare module 'vue/types/vue' {
  export interface Vue {
    $vuetify: Framework;
    $file: SelectFileDialog;
  }
}
