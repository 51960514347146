var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _vm.globalLoading
        ? _c("loading-component", {
            staticClass: "veles-background-full",
            attrs: { text: _vm.loadingText },
          })
        : _vm.globalErrorMessage
        ? _c(
            "v-container",
            { attrs: { fluid: "", "fill-height": "" } },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "", "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm8: "", md4: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "headline pb-2", attrs: { tag: "h1" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.appName) +
                              "\n          "
                          ),
                          _c("span", { staticClass: "subtitle-1" }, [
                            _vm._v(_vm._s(_vm.version)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v(_vm._s(_vm.globalErrorMessage)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-3",
                          attrs: { color: "primary" },
                          on: {
                            click: _vm.logOut,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.logOut.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Войти под другим пользователем")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("router-view"),
      _vm._v(" "),
      _c("select-file-dialog-component", { ref: "file" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }