var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    {
      ref: "ItemForm",
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _vm._l(_vm.rows, function (cols, i) {
        return _c(
          "v-row",
          { key: i, staticClass: "my-0 py-0 item-form-row" },
          _vm._l(cols, function (f) {
            return _c(
              "v-col",
              {
                key: f.name,
                class: [
                  "my-0",
                  "py-0",
                  "item-form-col",
                  f.name,
                  f.class ? f.class : "",
                ],
                attrs: { cols: f.cols },
              },
              [
                _vm.hasSlot(f)
                  ? _vm._t("field." + f.name, null, {
                      field: f,
                      attrs: _vm.getFieldProps(f),
                      item: _vm.item,
                    })
                  : f.widget && f.widget === "datetime"
                  ? _c(
                      "DatetimePicker",
                      _vm._b(
                        {
                          key: f.name,
                          attrs: {
                            textFieldProps: _vm.getFieldProps(f),
                            messages: _vm.messages_.datetime,
                          },
                          model: {
                            value: _vm.item[f.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.item, f.name, $$v)
                            },
                            expression: "item[f.name]",
                          },
                        },
                        "DatetimePicker",
                        _vm.getFieldProps(f),
                        false
                      )
                    )
                  : f.widget && f.widget === "password"
                  ? _c(
                      "PasswordField",
                      _vm._b(
                        {
                          key: f.name,
                          model: {
                            value: _vm.item[f.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.item, f.name, $$v)
                            },
                            expression: "item[f.name]",
                          },
                        },
                        "PasswordField",
                        _vm.getFieldProps(f),
                        false
                      )
                    )
                  : f.type === "boolean"
                  ? _c(
                      "v-checkbox",
                      _vm._b(
                        {
                          key: f.name,
                          model: {
                            value: _vm.item[f.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.item, f.name, $$v)
                            },
                            expression: "item[f.name]",
                          },
                        },
                        "v-checkbox",
                        _vm.getFieldProps(f),
                        false
                      )
                    )
                  : f.type === "file"
                  ? _c(
                      "v-file-input",
                      _vm._b(
                        {
                          key: f.name,
                          model: {
                            value: _vm.item[f.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.item, f.name, $$v)
                            },
                            expression: "item[f.name]",
                          },
                        },
                        "v-file-input",
                        _vm.getFieldProps(f),
                        false
                      )
                    )
                  : f.choices && f.widget && f.widget === "select"
                  ? _c(
                      "v-select",
                      _vm._b(
                        {
                          key: f.name,
                          attrs: { items: f.choices },
                          model: {
                            value: _vm.item[f.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.item, f.name, $$v)
                            },
                            expression: "item[f.name]",
                          },
                        },
                        "v-select",
                        _vm.getFieldProps(f),
                        false
                      )
                    )
                  : _c(
                      "v-text-field",
                      _vm._b(
                        {
                          key: f.name,
                          model: {
                            value: _vm.item[f.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.item, f.name, $$v)
                            },
                            expression: "item[f.name]",
                          },
                        },
                        "v-text-field",
                        _vm.getFieldProps(f),
                        false
                      )
                    ),
              ],
              2
            )
          }),
          1
        )
      }),
      _vm._v(" "),
      _vm._t("default", null, { valid: _vm.valid }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }