var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-text-field",
    _vm._b(
      {
        attrs: {
          placeholder: " ",
          autocomplete: "new-password",
          type: _vm.showPassword ? "text" : "password",
          "append-icon": _vm.appendIcon,
        },
        on: {
          "click:append": function ($event) {
            _vm.showPassword = !_vm.showPassword
          },
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      },
      "v-text-field",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }