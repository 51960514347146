var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-chip",
    _vm._b(
      { attrs: { color: "primary", outlined: "", small: "" } },
      "v-chip",
      _vm.$attrs,
      false
    ),
    [_vm._v(_vm._s(_vm.version))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }