import NgwConnector from '@nextgis/ngw-connector';
import { defined, flatten, objectAssign, unflatten } from '@nextgis/utils';

import defConfig from '../../../config_readonly.json';

import type {
  ComponentsConfig,
  LesConfig,
  ResourceModelName,
} from './interfaces';
import type {
  Credentials,
  ResourceIdKeynameDef,
  ResourceItem,
} from '@nextgis/ngw-connector';

class Config implements LesConfig {
  welcomeText?: string;
  demoMode?: boolean;
  ngwProjectFolderResource?: ResourceIdKeynameDef;
  baseUrl?: string;
  auth?: Credentials;

  lookupTableResourceGroup?: ResourceIdKeynameDef;

  setViewDelay = 300;

  app = 'ngwles';
  title = 'NextGIS Лес';
  org = '';
  qmsId = 465;

  resources: Record<ResourceModelName, string> = {
    plot: 'plot',
    turnpoint: 'turnpoint',
    plotwebmap: 'plotwebmap',
    annex3: 'annex3',
    annex3webmap: 'annex3webmap',
    annex4webmap: 'annex4webmap',
    annex4: 'annex4',
    declaration: 'declaration',
    space: 'space',
    spacewebmap: 'spacewebmap',
    reports: 'reports',
    reportwebmap: 'reportwebmap',
    overviewmap: 'overviewmap',
    plan: 'plan',
    planwebmap: 'planwebmap',
    nep: 'nep',
    nepturnpoint: 'nepturnpoint',
    nepwebmap: 'nepwebmap',
    infra: 'infra',
    infraturnpoint: 'infraturnpoint',
    infrawebmap: 'infrawebmap',
    firewebmap: 'firewebmap',
    vdfilter: 'vdfilter',
    vdfilterwebmap: 'vdfilterwebmap',
  };
  components: ComponentsConfig = {
    plot: {
      enabled: true,
      filterFields: ['KV', 'YEAR_DEV', 'SENDER_NAM', 'NOM_DOG', 'FARM'],
    },
    vdfilter: {
      enabled: false,
      filterFields: [],
      filterResource: 'vdfilterresource',
    },
    annex3: { enabled: true },
    annex4: { enabled: true },
    declaration: { enabled: true },
    reports: { enabled: true },
    plan: { enabled: false },
    fires: { enabled: true },
    'download-and-prepare-l8-s2': { enabled: true },
    poly2explication: { enabled: false },
    explication2poly: { enabled: false },
    'plot-selec': { enabled: false },
    overview: { enabled: true },
    infra: {
      enabled: true,
      filterFields: ['KV', 'SENDER_NAM', 'FARM'],
    },
    nep: { enabled: true, filterFields: ['NEP_NAME', 'NEP_CAT'] },
  };

  private _config: Partial<LesConfig> | null = null;

  componentEnabled(name: keyof Config['components']): boolean {
    return this.components[name] && this.components[name].enabled;
  }

  toJSON(): Record<string, any> {
    return this.getConfigProps();
  }

  getComponentConfig(
    name: string,
    prop: string,
    defVal?: unknown,
  ): unknown | undefined {
    const c = this._config;
    const component = c && c.components && c.components[name];
    if (component) {
      return component[prop] !== undefined ? component[prop] : defVal;
    }
  }

  getResourceName(resource: ResourceIdKeynameDef): string | undefined {
    const res = Object.entries(this.resources).find(([k, v]) => v === resource);
    if (res) {
      return res[0];
    }
  }

  getResourceKeyname(
    resource: ResourceModelName,
  ): ResourceIdKeynameDef | undefined {
    const res = Object.entries(this.resources).find(([k, v]) => k === resource);
    if (res) {
      return res[1];
    }
  }

  getResourceKeynameOrFail(resource: ResourceModelName): ResourceIdKeynameDef {
    const keyname = this.getResourceKeyname(resource);
    if (keyname) {
      return keyname;
    }
    throw new Error('Keyname does not exist');
  }

  mergeConfig(config: Partial<LesConfig>): this {
    for (const key in config) {
      if (key.startsWith('#')) {
        delete config[key as keyof LesConfig];
      }
    }
    const components = config.components;
    if (components) {
      for (const c in this.components) {
        components[c] = { ...this.components[c], ...components[c] };
      }
    }
    objectAssign(this, config);
    return this;
  }

  async applyRemoteConfig(
    connector?: NgwConnector,
  ): Promise<Partial<LesConfig>> {
    if (!this._config) {
      const config = await this.fetchConfig(connector);
      this._config = config;
      this.mergeConfig(config);
    }
    return this._config;
  }

  async fetchConfig(connector?: NgwConnector): Promise<Partial<LesConfig>> {
    connector = connector || NgwConnector.create(this);
    if (!defined(this.ngwProjectFolderResource)) {
      throw new Error('Config options `ngwProjectFolderResource` is not set');
    }
    const res = await connector.getResourceOrFail(
      this.ngwProjectFolderResource,
    );

    const metadata = res.resmeta.items;
    return this.readMetadata(metadata);
  }

  async saveConfigToNgw(): Promise<ResourceItem | undefined> {
    if (!defined(this.ngwProjectFolderResource)) {
      throw new Error('Config options `ngwProjectFolderResource` is not set');
    }
    const items = this.prepareMetadata();
    const connector = NgwConnector.create(this);
    const root = this.ngwProjectFolderResource;
    const ngwProjectFolderResource = isNaN(+root) ? root : Number(root);
    return connector.updateResource(ngwProjectFolderResource, {
      resmeta: { items },
    });
  }

  getConfigProps(full = true): Record<string, string | number | boolean> {
    const ignored: (keyof this)[] = full
      ? []
      : ['baseUrl', 'auth', 'ngwProjectFolderResource'];
    const config: Record<string, any> = {};
    const props = Object.getOwnPropertyNames(this) as (keyof this)[];
    props.forEach((p) => {
      if (p in this) {
        const val = this[p];
        if (
          typeof p === 'string' &&
          !p.startsWith('_') &&
          val &&
          typeof val !== 'function' &&
          ignored.indexOf(p) === -1
        ) {
          config[p] = val;
        }
      }
    });
    return config;
  }

  prepareMetadata(): Record<string, any> {
    const meta: Record<string, string | number> = {};
    const config = flatten(this.getConfigProps(false));
    for (const k in config) {
      const val = config[k];
      if (typeof val === 'boolean') {
        meta[k] = val ? 'TRUE' : 'FALSE';
      } else if (Array.isArray(val)) {
        meta[k] = val.join(',');
      } else {
        meta[k] = val;
      }
    }
    return meta;
  }

  readMetadata(meta: Record<string, any>): Partial<LesConfig> {
    const config: Record<string, any> = {};
    for (const key in meta) {
      const value = meta[key];
      if (typeof value === 'string') {
        const upperCase = value.toUpperCase();
        if (upperCase === 'TRUE') {
          config[key] = true;
        } else if (upperCase === 'FALSE') {
          config[key] = false;
        } else {
          config[key] = value;
        }
      } else {
        config[key] = value;
      }
    }
    return unflatten(config) as Partial<LesConfig>;
  }
}

const config = new Config();
config.mergeConfig(defConfig);

try {
  // @ts-ignore - for ts-node
  if (__DEV__ || !__BROWSER__) {
    {
      const localConfig = require('../../../config_local.json');
      config.mergeConfig(localConfig);
    }
  }
} catch (er) {
  // ignore
  // throw new Error('Local settings not available');
}

export { Config };
export default config;
