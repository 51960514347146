var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.active && _vm.overlay
    ? _c(
        "v-dialog",
        {
          attrs: { "hide-overlay": "", persistent: "", width: "300" },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.text || "Загрузка...") + "\n      "
                  ),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.active
    ? _c(
        "v-main",
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "fill-height": "" } },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "", "justify-center": "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { size: 50, color: "primary", indeterminate: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }