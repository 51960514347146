export const plotColor = '#30d5c8';

export const infraColor = '#ffa000';

export const nepColor = '#8b0000';

export const annex3Color = '#D4D183';

export const annex4Color = '#92B0BA';

export const reportColor = '#BA9BBF';

export const vdfilterColor = '#BA9BBF';

export const declarationColor = '#CCCCCC';

export const firesColor = '#EE6040';

export const downloadAndPrepareL8S2Color = '#31C0C9';

export const poly2explicationColor = '#a94a47';

export const explication2polyColor = '#4aa947';

export const plotSelecColor = '#595d48';

export const overviewColor = '#0070C5';

export const plotHighlight = '#ed3cca';
