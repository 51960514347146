import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { VContainer, VLayout, VMain, VProgressCircular } from 'vuetify/lib';

@Component({
  components: { VMain, VLayout, VProgressCircular, VContainer },
})
export default class LoadingComponent extends Vue {
  @Model('change', { type: Boolean, default: true }) readonly active!: boolean;
  @Prop({ type: String }) text!: string;
  @Prop({ type: Boolean, default: false }) overlay!: boolean;
}
