var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.dialogWidth },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        "append-icon": "mdi-calendar",
                        disabled: _vm.disabled,
                        loading: _vm.loading,
                        label: _vm.label,
                        value: _vm.formattedDatetime,
                        readonly: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "progress",
                            fn: function () {
                              return [
                                _vm._t("progress", function () {
                                  return [
                                    _c("v-progress-linear", {
                                      attrs: {
                                        color: "primary",
                                        indeterminate: "",
                                        absolute: "",
                                        height: "2",
                                      },
                                    }),
                                  ]
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    "v-text-field",
                    _vm.textFieldProps,
                    false
                  ),
                  _vm.readonly ? undefined : on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.display,
        callback: function ($$v) {
          _vm.display = $$v
        },
        expression: "display",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c(
                "v-row",
                { staticClass: "ma-2" },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.locale.date,
                          rules: _vm.dateRules,
                          placeholder: _vm.locale.datePlaceholder,
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.dateInput,
                          callback: function ($$v) {
                            _vm.dateInput = $$v
                          },
                          expression: "dateInput",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "menu",
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            "return-value": _vm.time,
                            transition: "scale-transition",
                            "offset-y": "",
                            "max-width": "290px",
                            "min-width": "290px",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.time = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.time = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            label: _vm.locale.time,
                                            rules: _vm.timeRules,
                                            placeholder:
                                              _vm.locale.timePlaceholder,
                                            "hide-details": "",
                                            dense: "",
                                            outlined: "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.timeMenu = false
                                            },
                                          },
                                          model: {
                                            value: _vm.timeInput,
                                            callback: function ($$v) {
                                              _vm.timeInput = $$v
                                            },
                                            expression: "timeInput",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.timeMenu,
                            callback: function ($$v) {
                              _vm.timeMenu = $$v
                            },
                            expression: "timeMenu",
                          },
                        },
                        [
                          _vm._v(" "),
                          _vm.timeMenu
                            ? _c("v-time-picker", {
                                ref: "timer",
                                attrs: { format: "24hr", "full-width": "" },
                                on: {
                                  "click:minute": function ($event) {
                                    return _vm.$refs.menu.save(_vm.time)
                                  },
                                },
                                model: {
                                  value: _vm.time,
                                  callback: function ($$v) {
                                    _vm.time = $$v
                                  },
                                  expression: "time",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-date-picker",
                _vm._b(
                  {
                    attrs: { "full-width": "", "no-title": "", scrollable: "" },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  },
                  "v-date-picker",
                  _vm.datePickerProps,
                  false
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _vm._t(
                "actions",
                function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", text: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.clearHandler.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.locale.clear))]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.okHandler },
                      },
                      [_vm._v(_vm._s(_vm.locale.ok))]
                    ),
                  ]
                },
                { parent: this }
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }