import { capitalize } from '@nextgis/utils';
import { Component, Vue } from 'vue-property-decorator';

import pkg from '../../../package.json';

@Component({})
export default class VersionChip extends Vue {
  get version(): string {
    return pkg.version + ' ' + capitalize(pkg.codename);
  }
}
