import { NoticeSetup } from '@nextgis_misc/vuetify/notice';
import { LoadingComponent } from '@veles/common/src/components/LoadingComponent';
import Vue from 'vue';
import VueRouter from 'vue-router';

import App from './components/App/App.vue';
import vuetify from './plugins/vuetify';
import router from './routers';
import store from './store';

import type { NoticeSetupOptions } from '@nextgis_misc/vuetify/notice';

import './styles/background.scss';

export { LoadingComponent };

const noticeOptions: NoticeSetupOptions = {
  vuetify,
  confirmDialog: { buttonTrueText: 'Подтвердить', buttonFalseText: 'Отменить' },
};

Vue.use(VueRouter);
Vue.use(NoticeSetup, noticeOptions);

export const app = (): any => {
  const app = new Vue({
    vuetify,
    el: '#app',
    router,
    store,
    render: (h) => h(App),
  });
  return app;
};

app();
