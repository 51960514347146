var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: { timeout: _vm.timeout, color: _vm.color },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _vm.dismissible
                ? _c(
                    "v-btn",
                    _vm._b(
                      { attrs: { icon: "" }, on: { click: _vm.dismiss } },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v(_vm._s(_vm.closeIcon))])],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      Boolean(_vm.icon)
        ? _c("v-icon", { attrs: { left: "" } }, [
            _vm._v("\n    " + _vm._s(_vm.icon) + "\n  "),
          ])
        : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.message) + "\n\n  "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }