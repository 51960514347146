/* eslint-disable @typescript-eslint/consistent-type-imports */
import { config } from '@veles/common';
import { LoadingComponent } from '@veles/common/src/components/LoadingComponent';

import {
  annex3Color,
  annex4Color,
  declarationColor,
  downloadAndPrepareL8S2Color,
  explication2polyColor,
  firesColor,
  infraColor,
  nepColor,
  overviewColor,
  plotColor,
  plotSelecColor,
  poly2explicationColor,
  reportColor,
  vdfilterColor,
} from './colors';
import AnnexIcon from './images/catalog/item_icon/annex.svg';
import DownloadAndPrepareL8S2Icon from './images/catalog/item_icon/download-and-prepare-l8-s2.svg';
import FiresIcon from './images/catalog/item_icon/fires.svg';
import InfraIcon from './images/catalog/item_icon/infra.svg';
import NepIcon from './images/catalog/item_icon/nep.svg';
import OverviewIcon from './images/catalog/item_icon/overview.svg';
import PlotIcon from './images/catalog/item_icon/plot.svg';
import ToolsIcon from './images/catalog/item_icon/tools.svg';

import type {
  CatalogItem,
  CatalogItemActionName,
} from './store/app/interfaces';
import type { VueConstructor } from 'vue';
import type { AsyncComponentFactory } from 'vue/types/options';

const catalogItemActionNames: Record<CatalogItemActionName, string> = {
  edit: 'Редактировать',
  create: 'Создать',
  list: 'Список',
  open: 'Открыть',
  map: 'Карта',
};

const catalog: CatalogItem[] = [
  {
    type: 'inventory',
    id: 'plot',
    icon: PlotIcon,
    title: 'Лесосеки',
    itemName: 'Лесосека',
    description: 'база данных лесосек',
    color: plotColor,
    info: 'Ведение базы данных лесосек',
    actions: [
      {
        name: 'map',
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotMap = await import('@veles/plot/src/components/PlotMap');
          return PlotMap;
        },
        router: { props: { mode: 'show' } },
      },
      {
        name: 'list',
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotListComponent = await import(
            '@veles/plot/src/components/PlotList'
          );
          return PlotListComponent;
        },
      },
      {
        name: 'create',
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotMap = await import('@veles/plot/src/components/PlotMap');
          return PlotMap;
        },
        router: { props: { mode: 'edit' } },
      },
      {
        name: 'edit',
        hidden: true,
        router: { path: '/:lesid', props: true },
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotMap = await import('@veles/plot/src/components/PlotMap');
          return PlotMap;
        },
      },
    ],
  },
  {
    type: 'inventory',
    id: 'infra',
    title: 'Инфраструктура',
    itemName: 'Инфраструктура',
    icon: InfraIcon,
    description: 'база данных инфраструктуры',
    color: infraColor,
    info: 'Ведение базы данных инфраструктуры',
    actions: [
      {
        name: 'map',
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotMap = await import('@veles/infra/src/components/PlotMap');
          return PlotMap;
        },
        router: { props: { mode: 'show' } },
      },
      {
        name: 'list',
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotListComponent = await import(
            '@veles/infra/src/components/PlotList'
          );
          return PlotListComponent;
        },
      },

      {
        name: 'create',
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotMap = await import('@veles/infra/src/components/PlotMap');
          return PlotMap;
        },
        router: { props: { mode: 'edit' } },
      },
      {
        name: 'edit',
        hidden: true,
        router: { path: '/:lesid', props: true },
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotMap = await import('@veles/infra/src/components/PlotMap');
          return PlotMap;
        },
      },
    ],
  },
  {
    type: 'inventory',
    id: 'nep',
    title: 'НЭП',
    itemName: 'НЭП',
    icon: NepIcon,
    description: 'база данных неэксплуатационных площадей',
    color: nepColor,
    info: 'Ведение базы данных НЭП',
    actions: [
      {
        name: 'map',
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotMap = await import('@veles/nep/src/components/PlotMap');
          return PlotMap;
        },
        router: { props: { mode: 'show' } },
      },
      {
        name: 'list',
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotListComponent = await import(
            '@veles/nep/src/components/PlotList'
          );
          return PlotListComponent;
        },
      },

      {
        name: 'create',
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotMap = await import(
            '@veles/nep/src/components/PlotMapCreator/PlotMapCreator.vue'
          );
          return PlotMap;
        },
        router: { props: { mode: 'edit' } },
      },
      {
        name: 'edit',
        hidden: true,

        router: { path: '/:lesid', props: true },
        component: async (): Promise<typeof import('*.vue')> => {
          const PlotMap = await import('@veles/nep/src/components/PlotMap');
          return PlotMap;
        },
      },
    ],
  },
  {
    type: 'report',
    id: 'annex3',
    title: 'Приложение №3',
    icon: AnnexIcon,
    description: 'к лесной декларации',
    info: 'Создание Приложений 3',
    color: annex3Color,
    actions: [
      {
        name: 'list',
        component: async (): Promise<typeof import('*.vue')> => {
          const ThirdAnnexList = await import(
            '../../annex/src/components/ThirdAnnexList/ThirdAnnexList'
          );
          return ThirdAnnexList;
        },
      },
      {
        name: 'create',
        title: 'Сформировать',
        component: async (): Promise<typeof import('*.vue')> => {
          const ThirdAnnex = await import(
            '../../annex/src/components/ThirdAnnex/ThirdAnnex.vue'
          );
          return ThirdAnnex;
        },
      },
      {
        name: 'edit',
        hidden: true,
        router: { path: '/:id', props: true },
        component: async (): Promise<typeof import('*.vue')> => {
          const ThirdAnnex = await import(
            '../../annex/src/components/ThirdAnnex/ThirdAnnex.vue'
          );
          return ThirdAnnex;
        },
      },
    ],
  },
  {
    type: 'report',
    id: 'annex4',
    title: 'Приложение №4',
    icon: AnnexIcon,
    description: 'к лесной декларации',
    info: 'Создание Приложений 4',
    color: annex4Color,
    actions: [
      {
        name: 'list',
        component: async (): Promise<typeof import('*.vue')> => {
          const List = await import(
            '../../annex/src/components/FourthAnnexList/FourthAnnexList'
          );
          return List;
        },
      },
      {
        name: 'create',
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/FourthAnnex/FourthAnnex.vue'
          );
          return ActionMap;
        },
      },
      {
        name: 'edit',
        hidden: true,
        router: { path: '/:id', props: true },
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/FourthAnnex/FourthAnnex.vue'
          );
          return ActionMap;
        },
      },
    ],
  },
  {
    type: 'report',
    // id: 'usage-recovery-reports',
    id: 'declaration',
    icon: AnnexIcon,
    color: declarationColor,
    title: 'Лесная декларация',
    description: '',
    info: 'Сформировать лесную декларацию',
    actions: [
      {
        name: 'list',
        component: async (): Promise<typeof import('*.vue')> => {
          const List = await import(
            '../../annex/src/components/Declaration/DeclarationList/DeclarationList'
          );
          return List;
        },
      },
      {
        name: 'create',
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/Declaration/Declaration/Declaration.vue'
          );
          return ActionMap;
        },
      },
      {
        name: 'edit',
        hidden: true,
        router: { path: '/:id', props: true },
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/Declaration/Declaration/Declaration.vue'
          );
          return ActionMap;
        },
      },
      {
        name: 'open',
        title: 'Лесная декларация (toolbox)',
        link: 'https://toolbox.nextgis.com/operation/ForestDeclaration2',
      },
    ],
  },
  {
    type: 'report',
    // id: 'usage-recovery-reports',
    id: 'reports',
    icon: AnnexIcon,
    color: reportColor,
    title: 'Приложение к отчету',
    description: 'об использовании/воспроизводстве лесов',
    info: 'Создание приложения к отчету об использовании/восстановлении лесов',
    actions: [
      {
        name: 'list',
        component: async (): Promise<typeof import('*.vue')> => {
          const List = await import(
            '../../annex/src/components/ReportList/ReportList'
          );
          return List;
        },
      },
      {
        name: 'create',
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/Report/Report.vue'
          );
          return ActionMap;
        },
      },
      {
        name: 'edit',
        hidden: true,
        router: { path: '/:id', props: true },
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/Report/Report.vue'
          );
          return ActionMap;
        },
      },
    ],
  },
  {
    type: 'report',
    id: 'plan',
    icon: AnnexIcon,
    color: reportColor,
    title: 'План участка с экспликацией',
    description: '',
    info: '',
    actions: [
      {
        name: 'list',
        component: async (): Promise<typeof import('*.vue')> => {
          const List = await import(
            '../../annex/src/components/Plan/PlanList/PlanList'
          );
          return List;
        },
      },
      {
        name: 'create',
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/Plan/Plan.vue'
          );
          return ActionMap;
        },
      },
      {
        name: 'edit',
        hidden: true,
        router: { path: '/:id', props: true },
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/Plan/Plan.vue'
          );
          return ActionMap;
        },
      },
    ],
  },
  {
    type: 'tools',
    // id: 'usage-recovery-reports',
    id: 'vdfilter',
    icon: ToolsIcon,
    color: vdfilterColor,
    title: 'Фильтр выделов',
    description: '',
    info: 'Создание фильтра выделов',
    actions: [
      {
        name: 'list',
        component: async (): Promise<typeof import('*.vue')> => {
          const List = await import(
            '../../annex/src/components/VdFilterList/VdFilterList'
          );
          return List;
        },
      },
      {
        name: 'create',
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/VdFilter/VdFilter.vue'
          );
          return ActionMap;
        },
      },
      {
        name: 'edit',
        hidden: true,
        router: { path: '/:id', props: true },
        component: async (): Promise<typeof import('*.vue')> => {
          const ActionMap = await import(
            '../../annex/src/components/VdFilter/VdFilter.vue'
          );
          return ActionMap;
        },
      },
    ],
  },
  {
    type: 'tools',
    id: 'fires',
    title: 'Пожары на территории',
    icon: FiresIcon,
    info: 'Интерактивная карта пожарной ситуации территории',
    color: firesColor,
    actions: [
      {
        name: 'open',
        component: async (): Promise<VueConstructor> => {
          const { FireMap } = await import('@veles/fires');
          return FireMap;
        },
      },
    ],
  },
  {
    type: 'tools',
    id: 'download-and-prepare-l8-s2',
    title: 'Скачать спутниковые данные',
    icon: DownloadAndPrepareL8S2Icon,
    info: 'Инструмент заказа и получения обработанных данных Landsat 8/Sentinel 2',
    color: downloadAndPrepareL8S2Color,
    actions: [
      {
        name: 'open',
        link: 'https://toolbox.nextgis.com/operation/les_remote_sensing',
      },
    ],
  },
  {
    type: 'tools',
    id: 'poly2explication',
    title: 'Полигон в экспликацию отвода',
    icon: ToolsIcon,
    info: 'Инструмент создаёт отчёт в формате Excel (xlsx) из полигона-лесосеки и привязочной линии',
    color: poly2explicationColor,
    actions: [
      {
        name: 'open',
        link: 'https://toolbox.nextgis.com/operation/poly2explication',
      },
    ],
  },
  {
    type: 'tools',
    id: 'explication2poly',
    title: 'Экспликация в полигон отвода',
    icon: ToolsIcon,
    info: 'Инструмент конвертирует отчет об экспликации в заданном формате в полигон отвода',
    color: explication2polyColor,
    actions: [
      {
        name: 'open',
        link: 'https://toolbox.nextgis.com/operation/explication2poly',
      },
    ],
  },
  {
    type: 'tools',
    id: 'plot-selec',
    title: 'Выборка лесосек',
    icon: ToolsIcon,
    color: plotSelecColor,
    actions: [
      {
        name: 'map',
        component: async (): Promise<unknown> => {
          const PlotMap = await import('@veles/plot/src/components/PlotMap');
          return PlotMap;
        },
        router: { props: { mode: 'show' } },
      },
      {
        name: 'list',
        component: async (): Promise<unknown> => {
          const PlotListComponent = await import(
            '@veles/plot/src/components/PlotList'
          );
          return PlotListComponent;
        },
      },
    ],
  },
  {
    type: 'tools',
    id: 'overview',
    title: 'Обзорная карта',
    icon: OverviewIcon,
    color: overviewColor,
    actions: [
      {
        name: 'open',
        component: async (): Promise<unknown> => {
          const { Overview } = await import('@veles/overview');
          return Overview;
        },
      },
    ],
  },
  // {
  //   type: 'tools',
  //   id: 'rent',
  //   title: 'Договоры аренды',
  //   actions: [
  //     {
  //       name: 'open',
  //       component: async () => {
  //         const { PlotListComponent } = await import('@veles/plot');
  //         return PlotListComponent;
  //       }
  //     }
  //   ]
  // }
];

let catalogAllowed: CatalogItem[] | null = null;

export function getModelTitle(modelName: string): string {
  const catalogItem = getCatalog().find((x) => x.id === modelName);
  return catalogItem ? catalogItem.itemName || catalogItem.title : '';
}

export function getCatalog(): CatalogItem[] {
  // const catalogAllowed: CatalogItem[] = [];
  if (catalogAllowed) {
    return catalogAllowed;
  }
  catalogAllowed = [];
  for (const x of catalog) {
    const catalogItemConfig = config.components[x.id];
    x.enabled = catalogItemConfig ? catalogItemConfig.enabled : false;
    if (x.actions) {
      for (const y of x.actions) {
        y.title = y.title || catalogItemActionNames[y.name];
        const component = y.component;
        if (component) {
          const asyncComponent: AsyncComponentFactory = () => ({
            component: component(),
            loading: LoadingComponent,
            // error: ErrorComponent,
            delay: 10,
            // timeout: 3000
          });
          y.componentFactory = asyncComponent;
        } else if (y.link) {
          //
        }
      }
    }
    if (x.enabled) {
      catalogAllowed.push(x);
    }
  }
  return catalogAllowed;
}
