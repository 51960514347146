var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      style: { zIndex: _vm.options.zIndex },
      attrs: { "max-width": _vm.options.width },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.cancel.apply(null, arguments)
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticStyle: { overflow: "hidden" } },
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c("v-file-input", {
            attrs: {
              accept: _vm.options.accept,
              label: _vm.message,
              multiple: _vm.options.multiple,
            },
            model: {
              value: _vm.files,
              callback: function ($$v) {
                _vm.files = $$v
              },
              expression: "files",
            },
          }),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary darken-1",
                    text: "",
                    disabled: !_vm.files,
                  },
                  on: { click: _vm.agree },
                },
                [_vm._v(_vm._s(_vm.options.acceptText))]
              ),
              _vm._v(" "),
              _vm.options.rejectBtn
                ? _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.cancel } },
                    [_vm._v(_vm._s(_vm.options.rejectText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }