import { config } from '@veles/common';
import VueRouter from 'vue-router';

import { appModule } from './store/app/app';
import Login from './views/Login/Login.vue';

import type { RouteConfig } from 'vue-router';

export const constantRoutes = (): RouteConfig[] => [
  {
    path: '/login',
    name: 'login',
    meta: { title: `Вход | ${config.title}`, anon: true },
    component: Login,
    props: true,
  },
  {
    path: '/',
    component: (): any => import('./views/MainPage/MainPage.vue'),
    children: [
      {
        path: '',
        name: 'main',
        meta: { title: config.title },
        component: (): any => import('./views/CatalogList/CatalogList.vue'),
      },
      // catalog pages initialized in App.ts
    ],
  },
  {
    path: '*',
    meta: { title: config.title },
    redirect: '/404',
  },
];

export const finalRoutes: RouteConfig[] = [
  {
    path: '/404',
    component: (): any => import('./views/404.vue'),
    meta: { title: `Страница не найдена | ${config.title}` },
  },
];

const createRouter = (routes: RouteConfig[] = []) => {
  return new VueRouter({
    mode: 'history', // require service support
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes: [...constantRoutes(), ...routes, ...finalRoutes], // https://router.vuejs.org/api/#router-addroutes
  });
};

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter(routes?: RouteConfig[]): void {
  const newRouter = createRouter(routes);
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

router.beforeEach((to, from, next) => {
  const user = appModule.user;
  const anon = to.meta && to.meta.anon;
  if (!user && !anon) {
    next({
      path: '/login',
      query: {
        next: to.redirectedFrom,
      },
    });
  } else {
    const arr: string | string[] | null | undefined =
      to.name && to.name.split('_');
    if (Array.isArray(arr) && arr[0] && arr[1]) {
      const [catalogItemId, actionName] = arr;
      appModule.setActiveCatalogAction({ actionName, catalogItemId });
    } else {
      appModule.setActiveCatalogAction();
    }
    next();
  }
});

export default router;
